import React from "react";
import { IntlContextConsumer } from "gatsby-plugin-intl";

const AnalystTableMobile = ({ tableData, className }) => {
  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <div className={className}>
          <div className="text-secondary tracking-wider py-3 border-b border-t border-input">
            {currentLocale === "fr" ? "LA FIRME (ANALYSTE)" : "FIRM (ANALYST)"}
          </div>
          {tableData.map((data, index) => (
            <div key={index} className="py-4 border-b border-input">
              <div className="mb-4 tracking-wider">{data.firm}</div>
              <div className="mb-4 italic tracking-wide">{data.analyst}</div>
              <a className="truncate text-email" href={`mailto:${data.email}`}>
                {data.email}
              </a>
            </div>
          ))}
        </div>
      )}
    </IntlContextConsumer>
  );
};

export default AnalystTableMobile;
