import React from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

import HeroWithImage from "../../components/heroWithImage";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import RRenderer from "../../components/richtextRenderer";
import AnalystTable from "../../components/analyst-table";
import AnalystTableMobile from "../../components/analyst-table-mobile";

import heroDesktop from "../../assets/heroDesktop/AnalystCoverage.png";
import heroMobile from "../../assets/heroMobile/AnalystCoverage.png";

const AnalysstCoverage = ({ data }) => {
  const text = data.allContentfulAnalystCoverage.edges[0].node.text;
  const tableData = data.allContentfulAnalystCoverage.edges[0].node.tableData;
  const intl = useIntl();
  return (
    <Layout inverted>
      <Seo title="ANALYST COVERAGE" />
      <HeroWithImage
        title={intl.locale === "fr" ? "COUVERTURE DES ANALYSTES" : "ANALYST COVERAGE"}
        heroMobile={heroMobile}
        heroDesktop={heroDesktop}
      />
      <div className="global-x-spacing md:max-w-70 mx-auto py-16 md:py-16">
        <RRenderer className="mb-8 md:mb-16" data={text} config={{ p: "mb-4" }} />
        <AnalystTable className="hidden md:block" tableData={tableData} />
        <AnalystTableMobile className="block md:hidden" tableData={tableData} />
      </div>
    </Layout>
  );
};

export default AnalysstCoverage;

export const query = graphql`
  query AnalystCoverage($locale: String) {
    allContentfulAnalystCoverage(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          text {
            raw
          }
          tableData {
            firm
            analyst
            location
            email
          }
        }
      }
    }
  }
`;
