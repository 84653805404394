import React from "react";
import { IntlContextConsumer } from "gatsby-plugin-intl";

const AnalystTable = ({ tableData, className }) => {
  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <div className={className}>
          <div className="grid grid-cols-4 gap-2 text-secondary tracking-wider py-3 border-b border-t border-input">
            <div>{currentLocale === "fr" ? "LA FIRME" : "FIRM"}</div>
            <div>{currentLocale === "fr" ? "ANALYSTE" : "ANALYST"}</div>
            <div>{currentLocale === "fr" ? "EMPLACEMENT" : "LOCATION"}</div>
            <div className="-ml-4">{currentLocale === "fr" ? "CONTACTEZ" : "CONTACT"}</div>
          </div>
          <div>
            {tableData.map((data, index) => (
              <div
                className="grid grid-cols-4 gap-2 items-center tracking-wider py-3 border-b border-input"
                key={index}
              >
                <div>{data.firm}</div>
                <div>{data.analyst}</div>
                <div>{data.location}</div>
                <a className="-ml-4 truncate text-email" href={`mailto:${data.email}`}>
                  {data.email}
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </IntlContextConsumer>
  );
};

export default AnalystTable;
